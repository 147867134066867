/* walletDropdown.css */
.wallet-dropdown {
    position: relative;
    display: inline-block;
}

.wallet-button {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.wallet-dropdown .dropdown-menu {
    display: none; /* Hide by default */
    position: absolute;
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-top: 5px;
    padding: 10px 0;
    border-radius: 4px;
}

.wallet-dropdown .dropdown-menu.show {
    display: block; /* Show when active */
}

.wallet-dropdown .dropdown-menu li {
    padding: 12px 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.wallet-dropdown .dropdown-menu li img.icon {
    margin-right: 8px;
}

.wallet-dropdown .dropdown-menu li {
    background-color: transparent;
    transition: background-color 0.3s ease;
}

.wallet-dropdown .dropdown-menu li:hover {
    background-color: #f1f1f1;
}

/* Ensure menu is correctly positioned on larger screens */
@media (min-width: 769px) {
    .wallet-dropdown .dropdown-menu {
        position: absolute; /* Keep absolute positioning for desktop */
    }
}
